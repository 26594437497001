import styled, { css } from "styled-components";

import { showVerticalScrollBarAlways } from "@sellernote/_shared/src/styles/functions";

import { COLOR, TEXT_COLOR } from "../../../styles/colors";
import { setFontStyle } from "../../../styles/typography";

const container = styled.div<{ isFiltered: boolean }>`
  display: flex;
  align-items: center;
  position: relative;

  > .dot {
    position: absolute;
    top: 1px;
    right: 0;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${COLOR.secondary_400};
  }

  > .icon {
    ${({ isFiltered }) =>
      isFiltered &&
      css`
        margin-right: 6px;
      `};
    cursor: pointer;
    display: inline-flex;
  }
`;

const filterSelectPanel = styled.div<{ isFullExplanationTable?: boolean }>`
  position: absolute;
  z-index: 1001;
  min-width: 245px;
  padding: 16px 12px;
  background-color: ${COLOR.wh};
  border: 1px solid ${COLOR.grayScale_800};
  max-width: 280px;

  > .input-search {
    /** 상세 테이블일 때 검색창과 리스트 버튼의 간격은 12px */
    margin-bottom: ${({ isFullExplanationTable }) =>
      isFullExplanationTable ? "12px" : "16px"};
  }

  > .options {
    text-align: left;
    max-height: 240px;
    overflow: auto;

    ${({ isFullExplanationTable }) =>
      /** 상세 테이블일 때 스크롤의 넓이가 더 얇음 */
      isFullExplanationTable
        ? css`
            &::-webkit-scrollbar:vertical {
              width: 4px;
              background-color: transparent;
              display: block;
            }
            &::-webkit-scrollbar-thumb:vertical {
              background-color: #d9d9d9;
              border: 3px solid transparent;
              border-radius: 4px;
            }
          `
        : showVerticalScrollBarAlways()};

    > .select-button:not(:first-of-type) {
      margin-top: 12px;
    }

    > .custom-select-button {
      &:not(:first-of-type) {
        margin-top: 12px;
      }

      &.label {
        padding-left: 12px;
      }

      > label {
        align-items: flex-start;

        > .select-icon-container {
          padding-top: 2px;
        }
      }
    }

    > .category-element {
      &:not(:first-child) {
        margin-top: 16px;
      }

      & ~ .custom-select-button {
        margin-top: 12px;
      }

      &.line {
        margin-top: 12px;
      }
    }
  }

  > .actions {
    display: flex;
    margin-top: ${({ isFullExplanationTable }) =>
      isFullExplanationTable ? "12px" : "16px"};

    > .button {
      flex: 1;
    }

    > .submit {
      margin-left: 8px;
    }
  }
`;

const categoryLine = styled.span`
  display: inline-block;
  margin-top: 12px;
  width: 100%;
  height: 1px;
  vertical-align: top;
  background-color: ${COLOR.grayScale_200};

  &:first-of-type {
    display: none;
  }
`;

const categoryLabel = styled.span`
  display: inline-block;
  ${setFontStyle("Body4")};
  vertical-align: top;
  color: ${TEXT_COLOR.black_3};
`;

const emptyMessage = styled.p`
  ${setFontStyle("Body3")};
  color: ${TEXT_COLOR.black_disabled};
  height: 240px;
`;

export default {
  container,
  filterSelectPanel,
  categoryLine,
  categoryLabel,
  emptyMessage,
};
